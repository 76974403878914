.rhap_main img {
  height: 24px;
}

.rhap_main * {
  outline: none;
}

.rhap_volume-bar, .rhap_volume-indicator {
  background: black;
}

.rhap_volume-indicator {
  width: 24px;
  height: 24px;
  margin-left: -12px;
  top: -10px;
  opacity: 1;
  box-shadow: rgba(134, 134, 134, 1) 0 3px 6px;
}

.rhap_header {
  font-size: 11px;
}

.rhap_main-controls, .rhap_controls-section {
  width: 100%;
}

.rhap_volume-controls {
  justify-content: center;
}

.rhap_play-pause-button {
  flex: 1;
}

.rhap_volume-container {
  flex: 3 1 auto;
}
